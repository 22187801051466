import { useAuthContext } from '../../context/AuthContext'
import { Link, NavLink } from 'react-router-dom'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded'
import Avatar from '../Avatar'
import './index.css'

function MobileNav () {
  const { currentUser } = useAuthContext()
  const isAdminOrOwner = isAuthorized({ policy: getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser })
  const currentOrg = (currentUser?.organizationRoles.find(role => role?.organization?.id === currentUser?.currentOrganizationId))?.organization
  const hasStudioAccess = currentOrg?.studioAccess
  const isContentCreator = currentOrg?.isContentCreator
  const hasOrganizationAndRoleStudioAccess = isAdminOrOwner && hasStudioAccess

  return (
    <div className='mobile-navigation'>
      {(currentUser && hasOrganizationAndRoleStudioAccess)
        ? <>
            <div className="mobile-nav-items">
              {isContentCreator ? <NavLink className='mobile-nav-item' to="/marketing"><AccountBalanceRoundedIcon/>Marketing</NavLink> : <></>}
              {isContentCreator ? <NavLink className='mobile-nav-item' to="/insights"><MapRoundedIcon/>Insights</NavLink> : <></>}
              <NavLink className='mobile-nav-item' to="/courses"><MapRoundedIcon/>Courses</NavLink>
              <NavLink>
                <Avatar className="mobile-nav-avatar" firstName={currentUser?.firstName} lastName={currentUser?.lastName} avatar={currentUser?.avatar}/>
                <div className="mobile-nav-profile-name">You</div>
              </NavLink>
            </div>
          </>
        : <Link to="/login" className='login'>Login</Link>
      }
    </div>
  )
}

export default MobileNav
