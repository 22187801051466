import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import ExerciseDisplay from '../ExerciseDisplay'
import SectionSettingsMenu from '../SectionSettingsMenu'
import { useOutsideAlerter } from '../../shared/utilities'
import './index.css'

function SectionsDisplay ({ handleChange, handleDelete, handleSave, handleReorder, displayErrorMessage, courseVersion, editExistingContent, editSection, editExercise, hasActions, courseType }) {
  const [sectionSettingsMenu, setSectionSettingMenu] = useState('')
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    userSelect: 'none',
    opacity: isDragging ? 0.6 : 1
  })

  const handleExerciseDrag = (source, destination) => {
    const sourceSectionId = source.droppableId.split('-')[1]
    const destinationSectionId = destination.droppableId.split('-')[1]
    const sourceSection = courseVersion?.sections.find(section => section?.id?.toString() === sourceSectionId?.toString())
    const destinationSection = courseVersion?.sections.find(section => section?.id.toString() === destinationSectionId?.toString())

    if (sourceSectionId === destinationSectionId) {
      const newExercisesOrder = [...sourceSection.exercises]
      const [movedExercise] = newExercisesOrder.splice(source.index, 1)
      newExercisesOrder.splice(destination.index, 0, movedExercise)

      handleReorder({
        type: 'exercises',
        sectionId: sourceSectionId,
        targetSectionId: sourceSectionId,
        sourceExercises: newExercisesOrder,
        destinationExercises: newExercisesOrder
      })
    } else {
      const newSourceExercises = [...sourceSection.exercises]
      const [movedExercise] = newSourceExercises.splice(source.index, 1)
      const newDestinationExercises = [...destinationSection.exercises]
      newDestinationExercises.splice(destination.index, 0, movedExercise)

      handleReorder({
        type: 'exercises',
        sectionId: sourceSectionId,
        targetSectionId: destinationSectionId,
        sourceExercises: newSourceExercises,
        destinationExercises: newDestinationExercises
      })
    }
  }

  const handleSectionDrag = (source, destination) => {
    const reorderedSections = reorder(
      courseVersion?.sections,
      source.index,
      destination.index
    )

    handleReorder({
      type: 'sections',
      data: reorderedSections
    })
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const isExerciseDrag = source.droppableId.includes('exercise')
    if (isExerciseDrag) {
      handleExerciseDrag(source, destination)
    } else {
      handleSectionDrag(source, destination)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable key="course" droppableId="course" type="COURSE">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="exercise-wrapper"
          >
            {courseVersion?.sections?.map((item, index) => (
              <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {displayErrorMessage?.section && editSection.id === item.id && <div className="program-error" style={{ maxWidth: '74%', margin: '1rem 0 0 2rem' }}>{displayErrorMessage?.section}</div>}
                    <div className="exercise-input-container" style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
                      <div style={{ display: 'flex', maxWidth: '100%', width: '80%' }}>
                        <DragIndicatorIcon style={{ marginTop: '5px' }} />
                        <div onClick={() => editSection.id !== item.id && editExistingContent({ section: item })} style={{ marginLeft: '0.5rem', width: '100%', maxWidth: '100%', fontWeight: '600', alignContent: 'center' }}>
                          <label>
                            {editSection.id === item.id &&
                              <input
                                style={{ padding: '0.5rem', margin: '0' }}
                                type="text"
                                placeholder="Title"
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                onChange={(e) =>
                                  handleChange({
                                    type: 'existingSection',
                                    attribute: 'title',
                                    value: e.target.value
                                  })
                                }
                                value={editSection?.title}
                              />}
                            {editSection.id !== item.id &&
                              <span style={{ display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                                {item?.title}
                              </span>}
                            {editSection.id !== item.id &&
                              item.achievement &&
                              <span style={{ marginTop: '5px', display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Achievement: <span style={{ fontWeight: '400' }}>{item?.achievement}</span></span>}
                          </label>
                        </div>
                        {editSection.id === item.id &&
                          <div className="program-draft-item-save-button-container">
                            <div onClick={() => handleSave({ saveType: 'saveExistingSection', sectionId: item.id })}><CheckIcon style={{ margin: '8px 0 0 5px', cursor: 'pointer' }} /></div>
                            <div onClick={() => editExistingContent({ type: 'section' })}><CloseIcon style={{ margin: '8px 0 0 5px', cursor: 'pointer' }} /></div>
                          </div>
                        }
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="button secondary" style={{ marginRight: '0.5rem', maxWidth: '140px', maxHeight: '30px', fontSize: '14px', padding: '0.5rem' }} onClick={() => handleChange({ type: 'newExercise', sectionId: item?.id })} >+ New</div>
                        <SectionSettingsMenu
                          handleDelete={handleDelete}
                          handleSave={handleSave}
                          sectionSettingsMenu={sectionSettingsMenu}
                          setSectionSettingMenu={setSectionSettingMenu}
                          useOutsideAlerter={useOutsideAlerter}
                          sectionId={item.id}
                          sectionName={item.title}
                          achievement={item.achievement}
                          handleReorder={handleReorder}
                        />
                      </div>
                    </div>
                    <div className="exercise-draft-container">
                      <ExerciseDisplay
                        handleChange={handleChange}
                        handleSave={handleSave}
                        handleDelete={handleDelete}
                        courseExercises={item.exercises}
                        editExercise={editExercise}
                        exerciseErrorMessage={displayErrorMessage?.exercise}
                        editExistingContent={editExistingContent}
                        sectionId={item.id}
                        getItemStyle={getItemStyle}
                        hasActions={hasActions}
                        courseType={courseType}
                      />
                    </div>
                    {index < courseVersion?.sections.length - 1 && <hr />}
                    </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SectionsDisplay
