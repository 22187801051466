import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useCreateProgramMutation, useUpdateProgramMutation } from '../../shared/queryHooks'
import { useState, useEffect } from 'react'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'

function CreateOrEditProgram ({ existingProgram }) {
  const navigate = useNavigate()
  const createProgram = useCreateProgramMutation()
  const updateProgram = useUpdateProgramMutation()
  const { currentUser } = useAuthContext()
  const { clearModal } = useModal()
  const defaultProgram = {
    id: '',
    name: '',
    description: '',
    publisherOrganizationId: currentUser?.currentOrganizationId,
    publisherName: currentUser?.organizationRoles.find(role => role.organization.id === currentUser?.currentOrganizationId)?.organization.name,
    publisherLogoURL: currentUser?.organizationRoles.find(role => role.organization.id === currentUser?.currentOrganizationId)?.organization.logoURL
  }
  const buttonText = existingProgram ? 'Update' : 'Create'
  const [program, setProgram] = useState(defaultProgram)
  const [programErrorMessage, setProgramErrorMessage] = useState('')

  useEffect(() => {
    if (existingProgram) {
      const formattedProgram = {
        id: existingProgram?.id,
        uid: existingProgram?.uid,
        name: existingProgram?.name,
        description: existingProgram?.description,
        publisherOrganizationId: existingProgram?.publisherOrganizationId,
        publisherName: existingProgram?.publisherName,
        publisherLogoURL: existingProgram?.publisherLogoURL,
        logoURL: existingProgram?.logoURL
      }
      setProgram(formattedProgram)
    }
  }, [])

  function handleChange ({ attribute, value }) {
    setProgram(prev => ({ ...prev, [attribute]: value }))
    setProgramErrorMessage('')
  }

  async function handleSave () {
    if (program?.name?.trim() === '') {
      setProgramErrorMessage('Program name is required')
      return
    }
    if (existingProgram) {
      await updateProgram.mutateAsync(program)
    } else {
      const createdProgram = await createProgram.mutateAsync(program)
      navigate(`/programs/${createdProgram?.id}`)
    }

    clearModal()
  }

  return (
    <>
    <div>
      {programErrorMessage && <div className="program-error">{programErrorMessage}</div>}
        <div style={{ margin: '0.5rem 0 1rem 0' }}>A program allows you to create and publish multiple courses. Once courses are made public, they can be subscribed to individually or users can subscribe to the entire program to access all included courses.</div>
        <input
          type="text"
          style={{ padding: '0.5rem', margin: '5px 0 0 0 !important', display: 'block' }}
          placeholder="Program Name"
          onChange={(e) => handleChange({ attribute: 'name', value: e.target.value })}
          value={program?.name}
        />
        <textarea
          type="text"
          style={{ padding: '0.5rem', margin: '2px 0 0 0', display: 'block', width: '100%' }}
          id="program-description"
          placeholder="Program Description"
          onChange={(e) => handleChange({ attribute: 'description', value: e.target.value })}
          value={program?.description}
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleSave()}>{(createProgram.isLoading || updateProgram.isLoading) ? <PuffLoader color="#fff" size={21} /> : buttonText}</div>
      </div>
    </>
  )
}

export default CreateOrEditProgram
