import Button from '../../components/Button'
import './index.css'

function ErrorPage ({ message, action, actionTitle }) {
  return (
    <div className="error-page">
      <div className="error-page-container">
        <div className="error-page-header">That&apos;s weird...</div>
        <div className="error-page-header-text">{message}</div>
        {action && <Button onClick={action}>{actionTitle}</Button>}
      </div>
    </div>
  )
}

export default ErrorPage
