import { useModal } from '../../context/ModalContext'
import { useState, useEffect } from 'react'

function AddOrEditAchievement ({ existingAchievement, handleSave, sectionId, sectionName }) {
  const [achievement, setAchievement] = useState()
  const { clearModal } = useModal()

  useEffect(() => {
    setAchievement(existingAchievement)
  }, [])

  function handleSaveOrDelete (deleteAchievement) {
    if (deleteAchievement) {
      handleSave({ sectionId, saveType: 'saveAchievement', achievement: '' })
      clearModal()
    } else {
      handleSave({ sectionId, saveType: 'saveAchievement', achievement })
      clearModal()
    }
  }

  return (
    <div className="promo-code-status-modal">
      <div style={{ wordBreak: 'break-all', marginTop: '1rem' }}>
        <div className="description">
          This will be awarded once all exercises in section <span style={{ fontWeight: 'bold' }}>{sectionName}</span> are completed
        </div>
        <input
            type="text"
            placeholder="Achievement Name"
            onChange={(e) => setAchievement(e.target.value)}
            value={achievement}
          />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div style={{ display: 'flex' }}>
          <div className="button" onClick={() => handleSaveOrDelete()}>{existingAchievement ? 'Save' : 'Add'}</div>
          {!!existingAchievement && <div className="button danger" style={{ marginLeft: '5px' }} onClick={() => handleSaveOrDelete(true)}>Delete</div>}
        </div>
      </div>
    </div>
  )
}

export default AddOrEditAchievement
