import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useRef } from 'react'
import { useModal } from '../../context/ModalContext'
import AddOrEditAchievement from '../../modals/AddOrEditAchievement'

function SectionSettingsMenu ({ handleDelete, handleSave, sectionSettingsMenu, setSectionSettingMenu, useOutsideAlerter, sectionId, sectionName, achievement }) {
  const { makeModal } = useModal()
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setSectionSettingMenu })

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={sectionSettingsMenu === sectionId ? null : () => setSectionSettingMenu(sectionId)}>
        <MoreHorizIcon style={{ marginTop: '5px', cursor: 'pointer' }}/>
      </div>
      {sectionSettingsMenu === sectionId &&
        <div className="program-settings-menu-dropdown-container" style={{ top: '2.5rem' }} ref={wrapperRef}>
          <Paper
            sx={{ width: 175 }}
            elevation={24}>
            <MenuList dense>
              <MenuItem
                  onClick={() => makeModal({
                    modal: (
                      <AddOrEditAchievement
                        handleSave={handleSave}
                        existingAchievement={achievement}
                        sectionId={sectionId}
                        sectionName={sectionName}
                      />
                    ),
                    title: achievement ? 'Edit Achievement' : 'Add Achievement'
                  })}>
                  <ListItemText>{achievement ? 'Edit' : 'Add'} Achievement</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => {
                handleDelete({ sectionId })
              }}>
                <ListItemText>Delete Section</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      }
    </div>
  )
}

export default SectionSettingsMenu
