import ExerciseSettingsMenu from '../ExerciseSettingsMenu'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { useOutsideAlerter } from '../../shared/utilities'
import AddOrEditAction from '../AddOrEditAction'
import { enumCourseTypes } from '../../shared/enums'
import './index.css'

function ExerciseDisplay ({ handleChange, handleDelete, handleSave, courseExercises, editExercise, exerciseErrorMessage, editExistingContent, sectionId, getItemStyle, hasActions, courseType }) {
  const [exerciseSettingsMenu, setExerciseSettingMenu] = useState('')
  const [actionDisplay, setActionDisplay] = useState('')

  return (
    <Droppable key="section" droppableId={`exercise-${sectionId}`} type="SECTION">
      {(provided) => (
        <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="exercise-wrapper"
        >
          {courseExercises?.map((item, index) => (
            <Draggable key={`exercise-${item?.id}`} draggableId={`exercise-${item?.id}`} index={index}>
              {(provided, snapshot) => (
                <div
                  className="exercise-wrapper"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  {exerciseErrorMessage && editExercise.id === item.id && <div className="program-error" style={{ maxWidth: '71.5%', margin: '1rem 0 0 2.5rem' }}>{exerciseErrorMessage}</div>}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '2rem', marginTop: '20px' }}>
                      <div style={{ display: 'flex', maxWidth: '100%', width: '80%', alignItems: 'center' }}>
                        <DragIndicatorIcon style={{ marginTop: '5px', marginRight: '10px' }} />
                        <div onClick={() => editExercise.id !== item.id && editExistingContent({ exercise: item })} style={{ marginLeft: '0.5rem', width: '100%', maxWidth: '100%', alignContent: 'center' }}>
                          <label>
                          {editExercise.id === item.id &&
                            <input
                              type="text"
                              placeholder="Title"
                              style={{ padding: '0.5rem', margin: '0' }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              onChange={(e) =>
                                handleChange({
                                  type: 'existingExercise',
                                  attribute: 'title',
                                  value: e.target.value
                                })
                              }
                              value={editExercise?.title}
                            />}
                            {editExercise.id !== item.id &&
                              <span style={{ display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', maxWidth: '65%' }}>
                              {item?.title}
                            </span>}
                          </label>
                          <label>
                            {editExercise.id === item.id &&
                              <input
                                style={{ padding: '0.5rem', margin: '5px 0 0 0' }}
                                type="text"
                                placeholder="Exercise URL"
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                onChange={(e) =>
                                  handleChange({
                                    type: 'existingExercise',
                                    attribute: 'url',
                                    value: e.target.value
                                  })
                                }
                                value={editExercise?.url}
                            />}
                            {editExercise.id !== item.id && (
                              <span style={{ fontWeight: '600', display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', maxWidth: '65%' }}>
                                {item?.url !== '' ? 'Media: ' : ''}
                                <span style={{ fontWeight: '400' }}>{item?.url !== '' ? item?.url : ' -'}</span>
                              </span>
                            )}
                          </label>
                        </div>
                        {editExercise.id === item.id &&
                          <div className="program-draft-item-save-button-container">
                            <div onClick={() => handleSave({ saveType: 'saveExistingExercise', exerciseId: item.id })}><CheckIcon style={{ margin: '8px 0 0 5px', cursor: 'pointer' }}/></div>
                            <div onClick={() => editExistingContent({ type: 'exercise' })}><CloseIcon style={{ margin: '8px 0 0 5px', cursor: 'pointer' }}/></div>
                          </div>
                        }
                    </div>
                    {editExercise.id !== item.id && (hasActions || courseType === enumCourseTypes.structured) && <AddOrEditAction
                      handleSave={handleSave}
                      exercise={item}
                      useOutsideAlerter={useOutsideAlerter}
                      setActionDisplay={setActionDisplay}
                      actionDisplay={actionDisplay}
                    />}
                    <ExerciseSettingsMenu
                      handleDelete={handleDelete}
                      exerciseSettingsMenu={exerciseSettingsMenu}
                      setExerciseSettingMenu={setExerciseSettingMenu}
                      useOutsideAlerter={useOutsideAlerter}
                      exercise={item}
                    />
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default ExerciseDisplay
