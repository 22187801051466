import { useModal } from '../../context/ModalContext'
import { useUpdatePromoCodeMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'

function PromoCodeStatus ({ setEditPromoCode, promoCode }) {
  const { clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const editPromoCodeStatus = useUpdatePromoCodeMutation()

  const handleSave = async () => {
    editPromoCodeStatus.mutate({ id: promoCode?.id, organizationId: currentUser?.currentOrganizationId, isCreatedBy: true })
    setEditPromoCode(null)
  }

  return (
    <div className="promo-code-status-modal">
      <div className="promo-code-modal-content" data-testid="promo-code-status-description">
        <p>Are you sure you want to revoke <span className="modal-code">{promoCode.code}</span>?</p>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button danger" data-testid="toggle-status-button" onClick={handleSave}>{editPromoCodeStatus?.isLoading ? <PuffLoader color='#fff' size={21} /> : 'Revoke code'}</div>
      </div>
    </div>
  )
}

export default PromoCodeStatus
