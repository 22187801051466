import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PromoCodeSettingsMenu from '../PromoCodeSettingsMenu'
import { useState } from 'react'
import { useOutsideAlerter } from '../../shared/utilities'
import './index.css'

function PromoCodeSettings ({ promoCode }) {
  const [editPromoCode, setEditPromoCode] = useState(null)

  const promoCodeSettingsMenu = (promoCode) => {
    return (
      <PromoCodeSettingsMenu
        promoCode={promoCode}
        useOutsideAlerter={useOutsideAlerter}
        setEditPromoCode={setEditPromoCode}
      />
    )
  }

  return (
    <div className="promo-code-settings" data-testid="promo-code-settings-container">
      <div className="button transparent promo-settings-button" data-testid="promo-code-settings-icon"
        onClick={editPromoCode === promoCode?.id ? () => setEditPromoCode(null) : () => setEditPromoCode(promoCode?.id)}>
        <MoreHorizIcon />
      </div>
      <div className="settings-menu-wrapper">
        { editPromoCode === promoCode?.id &&
        promoCodeSettingsMenu(promoCode) }
      </div>
    </div>
  )
}

export default PromoCodeSettings
