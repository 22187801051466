import { useState, useEffect } from 'react'
import MobileChartDisplay from '../MobileChartDisplay'
import ChartDisplay from '../ChartDisplay'

function InsightDisplay ({ data }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700)

  const chartData = {
    newSubscriptions: {
      labels: data.publicMonthlyNewCoursesSubscribed.map(row => row.month),
      datasets: [
        {
          label: 'New Courses Subscribed by Month',
          data: data.publicMonthlyNewCoursesSubscribed.map(row => row.count),
          borderColor: 'rgba(107, 131, 252, 1)',
          backgroundColor: 'rgba(107, 131, 252, 0.2)',
          fill: true
        },
        {
          label: 'New Courses Subscribed by Month (Private)',
          data: data.privateMonthlyNewCoursesSubscribed.map(row => row.count),
          borderColor: 'rgba(255, 174, 16, 1)',
          backgroundColor: 'rgba(255, 174, 16, 0.2)',
          fill: true
        }
      ]
    },
    subscriptionTotals: {
      labels: data.publicTotalMonthlySubscribedCourses.map(row => row.month),
      datasets: [
        {
          label: 'Monthly Course Subscriptions',
          data: data.publicTotalMonthlySubscribedCourses.map(row => row.count),
          borderColor: 'rgba(107, 131, 252, 1)',
          backgroundColor: 'rgba(107, 131, 252, 0.2)',
          fill: true
        },
        {
          label: 'Monthly Course Subscriptions (Private)',
          data: data.privateTotalMonthlySubscribedCourses.map(row => row.count),
          borderColor: 'rgba(255, 174, 16, 1)',
          backgroundColor: 'rgba(255, 174, 16, 0.2)',
          fill: true
        }
      ]
    },
    newEnrollments: {
      labels: data.publicMonthlyNewCourseMembersEnrolled.map(row => row.month),
      datasets: [
        {
          label: 'New Course Members Enrolled by Month',
          data: data.publicMonthlyNewCourseMembersEnrolled.map(row => row.count),
          borderColor: 'rgba(107, 131, 252, 1)',
          backgroundColor: 'rgba(107, 131, 252, 0.2)',
          fill: true
        },
        {
          label: 'New Course Members Enrolled by Month (Private)',
          data: data.privateMonthlyNewCourseMembersEnrolled.map(row => row.count),
          borderColor: 'rgba(255, 174, 16, 1)',
          backgroundColor: 'rgba(255, 174, 16, 0.2)',
          fill: true
        }
      ]
    },
    enrollmentTotals: {
      labels: data.publicTotalMonthlyCourseMembersEnrolled.map(row => row.month),
      datasets: [
        {
          label: 'Monthly Course Members Enrolled',
          data: data.publicTotalMonthlyCourseMembersEnrolled.map(row => row.count),
          borderColor: 'rgba(107, 131, 252, 1)',
          backgroundColor: 'rgba(107, 131, 252, 0.2)',
          fill: true
        },
        {
          label: 'Monthly Course Members Enrolled (Private)',
          data: data.privateTotalMonthlyCourseMembersEnrolled.map(row => row.count),
          borderColor: 'rgba(255, 174, 16, 1)',
          backgroundColor: 'rgba(255, 174, 16, 0.2)',
          fill: true
        }
      ]
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {isMobile
        ? <MobileChartDisplay chartData={chartData} />
        : <ChartDisplay chartData={chartData} />
      }
    </>
  )
}

export default InsightDisplay
