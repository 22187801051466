import { useEffect, useRef, useState } from 'react'

const useEffectOnce = (effect) => {
  const destroyFunc = useRef()
  const effectCalled = useRef(false)
  const renderAfterCalled = useRef(false)
  // eslint-disable-next-line no-unused-vars
  const [_, setVal] = useState(0)

  if (effectCalled.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFunc.current = effect()
      effectCalled.current = true
    }

    setVal(val => val + 1)

    return () => {
      if (!renderAfterCalled.current) { return }
      if (destroyFunc.current) { destroyFunc.current() }
    }
  }, [])
}

export {
  useEffectOnce
}
