import CreatePromoCode from '../../modals/CreatePromoCode'
import LoadingScreen from '../../components/LoadingScreen'
import { useAuthContext } from '../../context/AuthContext'
import PromoCodeSettings from '../../components/PromoCodeSettings'
import { useModal } from '../../context/ModalContext'
import { logError } from '../../shared/logger'
import { formatAmountAsDollars } from '../../shared/utilities'
import { useOrganizationPromoCodeQuery, useOrganizationSubscriptionQuery } from '../../shared/queryHooks'
import promotionSvg from '../../assets/promotions.svg'
import EmptyHero from '../../components/EmptyHero'
import useDocumentTitle from '../../shared/useDocumentTitle'
import './index.css'

function Marketing () {
  const { makeModal, clearModal } = useModal()
  useDocumentTitle('Marketing - ConnectBetter Studio')
  const emptyFieldPlaceholder = '—'
  const { currentUser } = useAuthContext()
  const { data: subscription } = useOrganizationSubscriptionQuery({ organizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })
  const { isLoading, isError, error, data: promoCodes } = useOrganizationPromoCodeQuery({ organizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser && !!subscription } })

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const createPromoCode = () => {
    makeModal({
      modal: <CreatePromoCode clearModal={clearModal} currentUser={currentUser} subscription={subscription}/>
    })
  }

  const action = [{
    onClick: createPromoCode,
    actionTitle: 'Create promotion'
  }]

  const promoCodesList = () => {
    if (promoCodes?.length > 0) {
      return promoCodes?.map(promo =>
        <div key={promo?.id} className="promo-code">
          <div className="promo-list-item">
            <div className="promotion-code">{promo?.code}</div>
          </div>
          <div className="promo-list-item">
            {formatAmountAsDollars(promo?.credit)}
          </div>
          <div className="promo-list-item">
            {formatAmountAsDollars(promo?.remainingCredits)} remaining
          </div>
          <div className="promo-list-item">
            {promo?.appliedByOrganization?.name || emptyFieldPlaceholder}
          </div>
          <div className="promo-list-item">
            {promo?.status}
          </div>
          <PromoCodeSettings promoCode={promo}/>
        </div>
      )
    }

    return (
      <EmptyHero image={promotionSvg} title="Promotions" description="You haven't created any promotions yet! Promotions can help you attract more organizations to sign up for your program." action={action} />
    )
  }

  return (
  <div className="billing-container">
    <div className="marketing-title-header" data-testid="marketing-header-title">Marketing</div>
    <div className="account-credit-container" data-testid="promo-credits-container">
      <div className='title'>{formatAmountAsDollars(subscription?.creditBalance)}</div>
      <div className='billing-next-attempt-at'>Account credit balance</div>
    </div>
    <div className="promotions-container" data-testid="created-promo-code-container">
      {promoCodes?.length > 0 && <div className="promo-codes-header">
        <div className='title'>Promotions</div>
        <div className="button secondary create-promo-code-button" data-testid="create-promo-code-button" onClick={createPromoCode}>Create promotion</div>
      </div>}
      {promoCodesList()}
    </div>
  </div>
  )
}

export default Marketing
