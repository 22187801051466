import { BarLoader } from 'react-spinners'
import './index.css'

function LoadingScreen () {
  return (
    <div className="loading-screen" data-testid="loading-bar">
      <BarLoader color='#51565f' width='100%'/>
    </div>
  )
}

export default LoadingScreen
