import { useState, useEffect } from 'react'
import { useProgramsQuery, useUpdateCourseProgramMutation } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { useAuthContext } from '../../context/AuthContext'
import { useModal } from '../../context/ModalContext'
import { PuffLoader } from 'react-spinners'
import LoadingScreen from '../../components/LoadingScreen'
import PublishCourse from '../PublishCourse'
import './index.css'

function AddOrUpdateCourseProgram ({ clearModal, courseVersionData, isInitialDraft, updatingCourseProgram }) {
  const { currentUser } = useAuthContext()
  const defaultProgram = {
    id: '',
    name: '',
    description: '',
    publisherOrganizationId: currentUser?.currentOrganizationId,
    publisherName: currentUser?.organizationRoles.find(role => role.organization.id === currentUser?.currentOrganizationId)?.organization.name,
    publisherLogoURL: currentUser?.organizationRoles.find(role => role.organization.id === currentUser?.currentOrganizationId)?.organization.logoURL
  }

  const programType = {
    addNew: 'addNew',
    addExisting: 'addExisting'
  }

  const { makeModal } = useModal()
  const updateCourseProgram = useUpdateCourseProgramMutation()
  const { isLoading, isError, error, data: programs } = useProgramsQuery({ publisherOrganizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })

  const [program, setProgram] = useState(defaultProgram)
  const [addProgramType, setAddProgramType] = useState(isInitialDraft ? programType.addNew : programType.addExisting)
  const [programErrorMessage, setProgramErrorMessage] = useState('')
  const [course, setCourse] = useState(courseVersionData)

  useEffect(() => {
    setCourse(courseVersionData)
    setAddProgramType(isInitialDraft ? programType.addNew : programType.addExisting)
  }, [])

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  function handleChange ({ type, attribute, value }) {
    if (type === programType.addNew) {
      setProgram(prev => ({ ...prev, [attribute]: value }))
      setProgramErrorMessage('')
    }

    if (type === programType.addExisting) {
      setCourse(prev => ({ ...prev, programId: value }))
    }
  }

  function handleProgramTypeToggle (type) {
    setAddProgramType(type)
    setProgram(defaultProgram)
    setCourse(prev => ({ ...prev, programId: 0 }))
    setProgramErrorMessage('')
  }
  const handleSave = (saveType) => {
    if (addProgramType === programType.addNew && program?.name?.trim() === '' && saveType === 'save') {
      setProgramErrorMessage('Program name is required')
      return
    }

    if (saveType === 'skip') {
      course.programId = 0
      clearModal()
      makeModal({
        modal: (
          <PublishCourse clearModal={clearModal} courseVersionData={course} isInitialDraft={isInitialDraft}/>
        ),
        title: 'Publish Course'
      })
      return
    }

    if (saveType === 'save') {
      clearModal()
      makeModal({
        modal: (
          <PublishCourse clearModal={clearModal} courseVersionData={course} isInitialDraft={isInitialDraft} program={program}/>
        ),
        title: 'Publish Course'
      })
      return
    }

    if (saveType === 'update') {
      updateCourseProgram.mutate({ body: { program, programId: addProgramType === programType.addExisting ? course.programId : null }, courseId: course.id })
      clearModal()
    }
  }

  return (
    <div>
      {programErrorMessage && <div className="program-error">{programErrorMessage}</div>}
      <div>{ updatingCourseProgram
        ? 'Do you want to update the program for this course?'
        : 'Do you want to add this course to a program? Programs allow you to group courses together and offer them as a bundle.' }
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem', padding: '0 0.5rem' }}>
        <div className={`course-program-update-type ${addProgramType === programType.addNew ? 'activeProgramType' : ''}`} onClick={() => handleProgramTypeToggle(programType.addNew)}>Add New</div>
        <div className={`course-program-update-type ${addProgramType === programType.addExisting ? 'activeProgramType' : ''}`} onClick={() => handleProgramTypeToggle(programType.addExisting)}>{updatingCourseProgram ? 'Update' : 'Add'} Existing</div>
      </div>
      {addProgramType === programType.addNew && (
        <div>
           <input
              type="text"
              style={{ padding: '0.5rem', margin: '5px 0 0 0 !important', display: 'block' }}
              placeholder="Program Name"
              onChange={(e) => handleChange({ type: programType.addNew, attribute: 'name', value: e.target.value })}
              value={program?.name}
            />
            <textarea
              type="text"
              style={{ padding: '0.5rem', margin: '2px 0 0 0', display: 'block', width: '100%' }}
              id="program-description"
              placeholder="Program Description"
              onChange={(e) => handleChange({ type: programType.addNew, attribute: 'description', value: e.target.value })}
              value={program?.description}
            />
        </div>
      )}
      {addProgramType === programType.addExisting && (
        <select className="programs-option-container" value={course.programId} onChange={(e) => handleChange({ type: programType.addExisting, attribute: 'programId', value: e.target.value })}>
        {programs.map((existingProgram) => (
          <option
            key={existingProgram.id}
            value={existingProgram.id}>
            {existingProgram.name}
          </option>
        ))}
      </select>
      )}

      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div>
          <div style={{ display: 'flex' }}>
            {isInitialDraft && <div style={{ marginRight: '0.5rem' }} className="button secondary" onClick={() => handleSave('skip')}>Skip</div>}
            {!updatingCourseProgram && <div className="button" onClick={() => handleSave('save')}>Save</div>}
            {updatingCourseProgram && <div className="button" onClick={() => handleSave('update')}>{updateCourseProgram.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Update'}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOrUpdateCourseProgram
