const stage = process.env.REACT_APP_STAGE

const CONFIG_PROD = {
  SENTRY_DSN: 'https://692b9a62a715421ebcc541b35307efb6@o1254450.ingest.sentry.io/4504679288995840',
  GOOGLE_API_KEY: 'AIzaSyD7MZCdtA-611D7vWj0Y7pHhr4nqTmwwa4',
  CRISP_WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40',
  STRIPE_API_KEY: 'pk_live_51LuLuGDGl4W5zqduyGL3qRtuNdJh4Z3CCzj0FMeJRGBmQXLHYt5abC9qvY9QvsccVBc40PeQfxF3Nod9b6qP58oT00rdcIJyqy',
  API_HOST: 'https://api.connectbetter.io',
  WEBSOCKET_HOST: 'wss://api.connectbetter.io',
  CDN_HOST: 'https://cdn.connectbetter.io',
  POSTHOG_API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH',
  STUDIO_HOST: 'https://studio.connectbetter.io',
  APP_HOST: 'https://app.connectbetter.io',
  IDENTITY_HOST: 'https://identity.avoda.co'
}

const CONFIG_STAGING = {
  SENTRY_DSN: '',
  GOOGLE_API_KEY: 'AIzaSyBJt4Q5J5pq7jBKPE7KMv3Lsrb3nbqFlwM',
  CRISP_WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40',
  STRIPE_API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf',
  API_HOST: 'https://staging-api.connectbetter.io',
  WEBSOCKET_HOST: 'wss://staging-api.connectbetter.io',
  CDN_HOST: 'https://staging-cdn.connectbetter.io',
  POSTHOG_API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH',
  STUDIO_HOST: 'https://staging-studio.connectbetter.io',
  APP_HOST: 'https://staging-app.connectbetter.io',
  IDENTITY_HOST: 'https://staging-identity.avoda.co'
}

const CONFIG_DEV = {
  SENTRY_DSN: '',
  GOOGLE_API_KEY: 'AIzaSyBJt4Q5J5pq7jBKPE7KMv3Lsrb3nbqFlwM',
  CRISP_WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40',
  STRIPE_API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf',
  API_HOST: 'http://localhost:4000',
  WEBSOCKET_HOST: 'ws://localhost:4000',
  CDN_HOST: 'https://staging-cdn.connectbetter.io',
  POSTHOG_API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH',
  STUDIO_HOST: 'http://localhost:3001',
  APP_HOST: 'http://localhost:3000',
  IDENTITY_HOST: 'https://localhost:3334'
}

const getConfigForEnvironment = () => {
  if (stage === 'production') {
    return CONFIG_PROD
  } else if (stage === 'staging') {
    return CONFIG_STAGING
  } else {
    return CONFIG_DEV
  }
}

const config = getConfigForEnvironment()

export default config
