import CourseDraft from '../../components/CourseDraft'
import { getCourse } from '../../shared/api'
import { useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import LoadingScreen from '../../components/LoadingScreen'
import { courseTypes, enumCourseTypes } from '../../shared/enums'

function CreateOrEditCourse () {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const programId = searchParams.get('programId')
  const courseType = searchParams.get('type')

  const courseTypeKey = Object.keys(courseTypes).find(key => courseTypes[key] === courseType)
  const defaultCourseType = courseTypeKey ? enumCourseTypes[courseTypeKey] : enumCourseTypes.selfPaced

  const [existingCourse, setExistingCourse] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchProgram = async () => {
      if (id) {
        setLoading(true)
        const existingCourse = await getCourse(id)
        const formattedSections = existingCourse?.courseVersion?.[0]?.sections?.map(section => ({
          ...section,
          achievement: section.achievements?.[0]?.name || ''
        }))

        const formattedCourse = {
          id: existingCourse?.id,
          uid: existingCourse?.uid,
          name: existingCourse?.name,
          description: existingCourse?.description,
          programId: existingCourse?.programId,
          sections: formattedSections,
          courseStatus: existingCourse?.courseStatus,
          displaySectionHeaders: existingCourse?.displaySectionHeaders,
          courseType: existingCourse?.courseType
        }

        setExistingCourse(formattedCourse)
        setLoading(false)
      }
    }
    fetchProgram()
  }, [])

  return (
    <>
      {!loading
        ? <div className="create-program">
           <CourseDraft existingCourse={existingCourse} programId={programId} courseType={defaultCourseType}/>
          </div>
        : <div>
            <LoadingScreen />
          </div>
      }
    </>
  )
}

export default CreateOrEditCourse
