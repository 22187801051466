import './index.css'

function InsightsNav ({ insightDisplay, setInsightDisplay }) {
  const insightItems = [
    { id: 'newSubscriptions', label: 'New Subscriptions' },
    { id: 'subscriptionTotals', label: 'Subscription Totals' },
    { id: 'newEnrollments', label: 'New Enrollments' },
    { id: 'enrollmentTotals', label: 'Enrollment Totals' }
  ]

  return (
    <div className="insights-container">
      {insightItems.map((item) => (
        <div
          key={item.id}
          id={item.id}
          className={`insight-button ${insightDisplay === item.id ? 'active' : ''}`}
          onClick={() => setInsightDisplay(item.id)}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

export default InsightsNav
