import { Chart } from 'chart.js/auto'
import { useEffect, useRef } from 'react'

function MobileChartDisplay ({ chartData }) {
  const newSubscriptionsRef = useRef(null)
  const subscriptionTotalsRef = useRef(null)
  const newEnrollmentsRef = useRef(null)
  const enrollmentTotalsRef = useRef(null)
  const chartInstances = useRef([])

  useEffect(() => {
    const createChart = (chartRef, chartData) => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d')
        const chart = new Chart(ctx, {
          type: 'line',
          data: chartData
        })
        chartInstances.current.push(chart)
      }
    }

    createChart(newSubscriptionsRef, chartData.newSubscriptions)
    createChart(subscriptionTotalsRef, chartData.subscriptionTotals)
    createChart(newEnrollmentsRef, chartData.newEnrollments)
    createChart(enrollmentTotalsRef, chartData.enrollmentTotals)

    return () => {
      chartInstances.current.forEach(chart => chart.destroy())
    }
  }, [])

  return (
    <div style={{ padding: '2rem', width: '100vw', overflowY: 'scroll' }}>
      <canvas ref={newSubscriptionsRef} style={{ width: '100%', height: '300px', maxHeight: '300px', minHeight: '300px', margin: 'auto', marginBottom: '1rem' }}></canvas>
      <canvas ref={subscriptionTotalsRef} style={{ width: '100%', height: '300px', maxHeight: '300px', minHeight: '300px', margin: 'auto', marginBottom: '1rem' }}></canvas>
      <canvas ref={newEnrollmentsRef} style={{ width: '100%', height: '300px', maxHeight: '300px', minHeight: '300px', margin: 'auto', marginBottom: '1rem' }}></canvas>
      <canvas ref={enrollmentTotalsRef} style={{ width: '100%', height: '300px', maxHeight: '300px', minHeight: '300px', margin: 'auto', marginBottom: '1rem' }}></canvas>
    </div>
  )
}

export default MobileChartDisplay
