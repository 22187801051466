import { Link } from 'react-router-dom'

function Button ({ children, to, href, ...props }) {
  if (to) {
    return (
      <Link to={to} className="button" {...props}>
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className="button" {...props}>
        {children}
      </a>
    )
  }

  return (
    <button className="button" {...props}>
      {children}
    </button>
  )
}

export default Button
