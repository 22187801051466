import { useState, useEffect, useRef } from 'react'
import { Paper, MenuList, MenuItem } from '@mui/material'
import './index.css'

function AddOrEditAction ({ handleSave, exercise, useOutsideAlerter, setActionDisplay, actionDisplay }) {
  const wrapperRef = useRef(null)
  const dueByDisplayText = actionDisplay !== exercise.id ? exercise.publisherDueBy === 0 ? 'Not due after subscribing' : `Due after ${exercise.publisherDueBy} days of subscribing` : ''
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setActionDisplay })
  const [publisherDueBy, setPublisherDueBy] = useState(0)

  useEffect(() => {
    setPublisherDueBy(isNaN(exercise.publisherDueBy) ? 0 : exercise.publisherDueBy)
  }, [exercise.publisherDueBy])

  function handleSaveOrDelete (deleteAction) {
    if (deleteAction) {
      handleSave({ exerciseId: exercise.id, saveType: 'deleteAction', publisherDueBy: 0 })
      setActionDisplay('')
    } else {
      if (isNaN(publisherDueBy) || publisherDueBy === '') {
        setActionDisplay('')
        setPublisherDueBy(0)
        return
      }
      handleSave({ exerciseId: exercise.id, saveType: 'saveAction', publisherDueBy: parseInt(publisherDueBy) })
      setActionDisplay('')
    }
  }

  return (
    <div style={{ position: 'relative', marginRight: '1rem' }}>
      <div onClick={actionDisplay === exercise.id ? () => setActionDisplay('') : () => setActionDisplay(exercise.id)}>
        <div style={{ marginTop: '5px', cursor: 'pointer' }}>{dueByDisplayText}</div>
      </div>
      {actionDisplay === exercise.id &&
        <div className="exercise-action-menu-dropdown-container" ref={wrapperRef}>
          <Paper
            sx={{ width: 175 }}
            elevation={24}>
            <MenuList dense>
              <MenuItem>
              <div style={{ width: '100%' }}>
                <div style={{ margin: '0.25rem 0 0.5rem 0', fontSize: '16px' }}>
                  <span>Due after</span>
                  <input
                    type="number"
                    placeholder="Days until due after subscribed"
                    max="365"
                    min="-1"
                    style={{ width: '5rem', margin: '0 5px' }}
                    onChange={(e) => {
                      if (e.target.value > 365) {
                        e.target.value = 365
                      }
                      if (e.target.value < 0) {
                        e.target.value = 0
                      }
                      setPublisherDueBy(e.target.value)
                    }}
                    value={publisherDueBy}
                  />
                  <span>days of subscribing</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div className="button" onClick={() => handleSaveOrDelete()}>{exercise.publisherAction ? 'Save' : 'Add'}</div>
                  {!!exercise.publisherAction && <div className="button danger" style={{ marginLeft: '5px' }} onClick={() => handleSaveOrDelete(true)}>Delete</div>}
                </div>
              </div>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      }
    </div>
  )
}

export default AddOrEditAction
