import { Chart } from 'chart.js/auto'
import { useEffect, useRef, useState } from 'react'
import InsightsNav from '../../components/InsightNav'

function ChartDisplay ({ chartData }) {
  const [insightDisplay, setInsightDisplay] = useState('newSubscriptions')
  const chartRef = useRef(null)
  let chartInstance = null

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')

    chartInstance = new Chart(ctx, {
      type: 'line',
      data: chartData[insightDisplay]
    })

    return () => {
      if (chartInstance) {
        chartInstance.destroy()
      }
    }
  }, [insightDisplay])

  return (
    <>
      <InsightsNav insightDisplay={insightDisplay} setInsightDisplay={setInsightDisplay} />
      <div style={{ padding: '4rem', width: '100vw' }}>
        <canvas ref={chartRef} style={{ width: '100%', height: '100%', maxWidth: '90vw', maxHeight: '70vh', minHeight: '70vh', margin: 'auto' }}></canvas>
      </div>,
    </>
  )
}

export default ChartDisplay
