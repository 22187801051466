import { useAuthContext } from '../../context/AuthContext'
import { useState, useRef } from 'react'
import { useOutsideAlerter } from '../../shared/utilities'
import Avatar from '../Avatar'
import './index.css'

function AvatarSignOut () {
  const { signOutUser, currentUser } = useAuthContext()
  const [menuSelected, setMenuSelected] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setMenuSelected })

  return (
    <div ref={wrapperRef} className="avatar-sign-out-wrapper">
      <div className='login avatar' onClick={() => setMenuSelected(!menuSelected)}><Avatar className="nav-avatar" firstName={currentUser.firstName} lastName={currentUser.lastName} /></div>
      {menuSelected &&
        <div className="signout-dropdown">
          <div className="button danger" onClick={signOutUser}>Sign Out</div>
        </div>
      }
    </div>
  )
}

export default AvatarSignOut
