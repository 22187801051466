import { useAuthContext } from '../../context/AuthContext'
import { useCoursesQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getSortedContent } from '../../shared/utilities'
import { useModal } from '../../context/ModalContext'
import insightsIllustration from '../../assets/insights.svg'
import LoadingScreen from '../../components/LoadingScreen'
import EmptyHero from '../../components/EmptyHero'
import CheckIcon from '@mui/icons-material/CheckRounded'
import SyncIcon from '@mui/icons-material/Sync'
import Logo from '../../components/Logo'
import CreateOrEditProgram from '../../modals/CreateOrEditProgram'
import CourseType from '../../modals/CourseType'
import AddOrUpdateCourseProgram from '../../modals/AddOrUpdateCourseProgram'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import './index.css'

function Courses () {
  const navigate = useNavigate()
  const { makeModal, clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const { isLoading, isError, error, data: courses } = useCoursesQuery({ publisherOrganizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })
  const [searchParms, setSearchParms] = useState('')

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const handleCreateCourse = () => {
    makeModal({
      modal: (
        <CourseType />
      ),
      title: 'Select Course Type'
    })
  }

  const programItemDisplay = (programCourses) => {
    if (programCourses === undefined || programCourses.length === 0) {
      return null
    }

    const isPublisher = currentUser?.currentOrganizationId === programCourses?.[0]?.program?.publisherOrganizationId
    const isApproved = programCourses.some(checkedCourse => checkedCourse?.courseStatus === status.PUBLIC || checkedCourse?.courseStatus === status.PRIVATE || checkedCourse?.courseVersion?.[0].isApproved)
    const highestCourseStatus = programCourses
      .map(course => course.courseStatus)
      .reduce((highest, current) => {
        return statusPriority[current] < statusPriority[highest] ? current : highest
      }, status.DRAFT)

    const activeChip = {
      icon: isPublisher && isApproved ? <CheckIcon /> : <SyncIcon />,
      label: <div className='subscribe-chip'>
        {isPublisher ? (isApproved ? highestCourseStatus : 'In Review') : 'Subscribed'}
        <MoreHorizIcon/>
      </div>
    }

    return (
      <div key={programCourses?.[0]?.program?.id} className='program-card-container' style={{ cursor: isPublisher ? 'pointer' : 'default' }} onClick={() => isPublisher ? navigate(`/programs/${programCourses?.[0]?.program?.id}`) : null}>
        <div className="chip-container" onClick ={(e) => {
          e.stopPropagation()
          makeModal({
            modal: (
              <CreateOrEditProgram existingProgram={programCourses?.[0]?.program}/>
            ),
            title: 'Edit Program'
          })
        }}>
          <Chip {...activeChip} size="small" variant="outlined" sx={{ marginRight: '0.5rem' }}/>
        </div>
        <div>
          <div className="program-metadata-container">
            <Logo logoUrl={programCourses?.[0]?.program?.publisherLogoURL} name={programCourses?.[0]?.program?.name} alt={'program logo'}/>
            <div className="program-metadata">
              <div className="program-name">{programCourses?.[0]?.program?.name}</div>
              <div className="program-publisher-name">{programCourses?.[0]?.program?.publisherName}</div>
            </div>
          </div>
          <div style={{ marginTop: '0.5rem', fontWeight: '600' }}>{programCourses?.length} Courses</div>
        </div>
      </div>
    )
  }

  const courseItemDisplay = (course) => {
    const isApproved = course?.courseStatus === status.PUBLIC || course?.courseStatus === status.PRIVATE || course?.courseVersion?.[0].isApproved
    const activeChip = {
      icon: isApproved ? <CheckIcon /> : <SyncIcon />,
      label: <div className='subscribe-chip'>
        {status[course?.courseStatus]}
        <MoreHorizIcon/>
      </div>
    }

    return (
      <div key={course?.id} className='course-card-container' style={{ cursor: 'pointer' }} onClick={() => navigate(`/courses/${course?.id}/draft?programId=${course.programId}`)}>
        <div className="chip-container"onClick ={(e) => {
          e.stopPropagation()
          makeModal({
            modal: (
              <AddOrUpdateCourseProgram updatingCourseProgram={true} courseVersionData={course} clearModal={clearModal}/>
            ),
            title: 'Edit Program'
          })
        }}>
          <Chip {...activeChip} size="small" variant="outlined" sx={{ marginRight: '0.5rem' }}/>
        </div>
        <div className="program-metadata-container">
          <Logo logoUrl={course?.publisherLogoURL} name={course?.name} alt={'program logo'}/>
          <div className="program-metadata">
            <div className="program-name">{course?.name}</div>
            <div className="program-publisher-name">{course?.program?.publisherName}</div>
          </div>
        </div>
      </div>
    )
  }

  const handleSearch = (search) => {
    setSearchParms(search)
  }

  const status = {
    DRAFT: 'Draft',
    PRIVATE: 'Private',
    PUBLIC: 'Public'
  }

  const statusPriority = {
    [status.PUBLIC]: 1,
    [status.PRIVATE]: 2,
    [status.DRAFT]: 3
  }

  const action = [{
    onClick: () => handleCreateCourse(),
    actionTitle: 'Create Course'
  }]

  const groupedCourses = courses?.reduce((acc, course) => {
    if (!acc[course.programId]) {
      acc[course.programId] = []
    }
    acc[course.programId].push(course)
    return acc
  }, {})

  const generateCourseOrProgramDisplay = Object.values(groupedCourses || {}).map((programCourses) => {
    return programCourses.length > 1
      ? programItemDisplay(programCourses)
      : courseItemDisplay(programCourses[0])
  })

  return (
    <>
      <div className='program-header-container'>
        <div className="marketing-title-header">Courses</div>
        <div className="button secondary program-button" onClick={() => handleCreateCourse()}>Create Course</div>
      </div>
      <div className="program-search">
        <input
          className="program-input"
          type="text"
          placeholder="Search Courses or Programs..."
          onChange={(e) => handleSearch(e.target.value)}
          value={searchParms}
        />
      </div>
      <div className="programs-container">
        {courses?.length > 0
          ? <div className="program-wrapper">
              {searchParms
                ? getSortedContent({ searchParms, searchItemDisplay: courseItemDisplay, contents: courses })
                : generateCourseOrProgramDisplay}
            </div>
          : <EmptyHero
              image={insightsIllustration}
              title="Course"
              data-testid="insights-empty-hero"
              description="You don't have any courses yet. Once you have created a course, analytics will appear here."
              action={action}
            />
        }
      </div>
    </>
  )
}

export default Courses
