import { createContext, useContext, useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/CloseRounded'

const ModalContext = createContext()

const Modal = ({ modal, clearModal, modalClose, title, withoutOverflow }) => {
  useEffect(() => {
    const bind = e => {
      if (e.keyCode !== 27) {
        return
      }

      if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
        return
      }

      clearModal()
    }

    document.addEventListener('keyup', bind)
    return () => document.removeEventListener('keyup', bind)
  }, [modal, clearModal])

  return (
    <div className="modal-overlay">
      <div className="modal-close" data-testid="modal-close-background" onClick={modalClose ? clearModal : undefined} />
      <div className={`modal ${withoutOverflow ? 'without-overflow' : ''}`}>
        <div className="modal-header">
          <div>{title}</div>
          <CloseIcon className="modal-close-button" data-testid="modal-close-button" onClick={clearModal}/>
        </div>
        <div className="modal-content">
          {modal}
        </div>
      </div>
    </div>
  )
}

const ModalProvider = props => {
  const [modal, setModal] = useState()
  const [modalClose, setModalClose] = useState(true)
  const [title, setTitle] = useState()
  const [withoutOverflow, setWithoutOverflow] = useState(false)

  const clearModal = useCallback(() => {
    setTitle()
    setModalClose()
    setModal()
    setWithoutOverflow()
  }, [setModal, setTitle, setModalClose, setWithoutOverflow])

  function makeModal ({ modal, modalClose, title, overflow }) {
    setModalClose(modalClose ?? true)
    setTitle(title)
    setModal(modal)
    setWithoutOverflow(overflow)
  }

  return (
    <ModalContext.Provider value={{ clearModal, makeModal, setTitle }} {...props} >
      {props.children}
      {modal && <Modal modal={modal} clearModal={clearModal} modalClose={modalClose} title={title} withoutOverflow={withoutOverflow} />}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}

export { ModalProvider, useModal }
