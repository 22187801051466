import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useModal } from '../context/ModalContext'
import { logError } from './logger'
import * as api from './api'

export function useUserQuery ({ organizationId, options } = {}) {
  return useQuery(['user'], () => api.getUser(organizationId), options)
}

export function useCoursesQuery ({ publisherOrganizationId, options }) {
  return useQuery(['publishers', publisherOrganizationId, 'courses'], () => api.getPublisherCourses(publisherOrganizationId), options)
}

export function useProgramsQuery ({ publisherOrganizationId, options }) {
  return useQuery(['publishers', publisherOrganizationId, 'programs'], () => api.getPublisherPrograms(publisherOrganizationId), options)
}

export function useProgramQuery ({ publisherOrganizationId, programId, options }) {
  return useQuery(['publishers', publisherOrganizationId, 'program'], () => api.getPublisherProgram({ publisherOrganizationId, programId }), options)
}

export function usePublicProgramsQuery () {
  return useQuery(['public_programs'], api.getPublicPrograms)
}

export function useOrganizationPromoCodeQuery ({ organizationId, options }) {
  return useQuery(['organizations', organizationId, 'promoCodes'], () => api.getOrganizationPromoCodes(organizationId), options)
}

export function useOrganizationSubscriptionQuery ({ organizationId, options }) {
  return useQuery(['organizations', organizationId, 'subscription'], () => api.getOrganizationSubscription(organizationId), options)
}

export function useStudioInsightsQuery ({ organizationId, options }) {
  return useQuery(['publishers', organizationId, 'insights'], () => api.getStudioInsights(organizationId), options)
}

export function useCreatePromoCodeMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.postPromoCode, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['organizations', response?.createdBy, 'promoCodes'])
      const previousPromoCodes = queryClient.getQueryData(['organizations', response?.createdBy, 'promoCodes'])
      const updatedPromoCodesList = [...previousPromoCodes, response]
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], updatedPromoCodesList)
      queryClient.invalidateQueries({ queryKey: ['organizations', response?.createdBy, 'subscription'] })
      return { previousPromoCodes }
    },
    onError: (error, response, context) => {
      logError(error)
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], context.previousPromoCodes)
    }
  })
}

export function useUpdatePromoCodeMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(api.putPromoCode, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['organizations', response?.createdBy, 'promoCodes'])
      const previousPromoCodes = queryClient.getQueryData(['organizations', response?.createdBy, 'promoCodes'])
      const updatedPromoCodes = previousPromoCodes?.map(promoCode =>
        promoCode?.id === response?.id
          ? response
          : promoCode)

      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], updatedPromoCodes)
      queryClient.invalidateQueries({ queryKey: ['organizations', response?.createdBy, 'subscription'] })
      clearModal()
      return { previousPromoCodes }
    },
    onError: (error, response, context) => {
      logError(error)
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], context.previousPromoCodes)
    }
  })
}

export function useCreateProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.postProgram, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['publishers', response?.organizationId, 'programs'])
      queryClient.invalidateQueries({ queryKey: ['publishers', response?.organizationId, 'programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useUpdateProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.putProgram, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['publishers', response?.publisherOrganizationId, 'courses'])
      queryClient.invalidateQueries({ queryKey: ['publishers', response?.publisherOrganizationId, 'courses'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useAddOrganizationProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.addOrganizationProgram, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useRemoveOrganizationProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.removeOrganizationProgram, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useCourseQuery ({ publisherOrganizationId, courseId, options }) {
  return useQuery(['course', courseId], () => api.getCourse(courseId), options)
}

export function useCreateCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.postCourse, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useUpdateCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.putCourse, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export function useUpdateCourseProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(api.putCourseProgram, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['publishers', response.organizationId, 'courses'])
      queryClient.invalidateQueries({ queryKey: ['publishers', response.organizationId, 'courses'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}
