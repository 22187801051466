import { useState } from 'react'
import { formattedInputAmount, formatAmountAsDollars, formatAmountAsCents } from '../../shared/utilities'
import { useCreatePromoCodeMutation } from '../../shared/queryHooks'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import './index.css'

function CreatePromoCode ({ clearModal, currentUser, subscription }) {
  const viewEnum =
    {
      creation: 'CREATION',
      created: 'CREATED'
    }

  const [view, setView] = useState(viewEnum.creation)
  const [promoCodeAmount, setPromoCodeAmount] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [promoCode, setPromoCode] = useState('')

  const postPromoCode = useCreatePromoCodeMutation()

  const isValidAmount = (value) => {
    if (formatAmountAsCents(value) > subscription?.creditBalance) {
      return false
    }
    return true
  }

  const checkAndSetAmount = (value) => {
    setPromoCodeAmount(value)
    if (isValidAmount(value)) {
      setErrorMessage('')
    } else {
      setErrorMessage('Your amount is higher then your available credits')
    }
  }

  const isEmpty = (value) => {
    if (value?.length > 0) {
      return true
    }
    setErrorMessage('Please enter an amount for your promo code')
    return false
  }

  const createPromoCode = async () => {
    if (isEmpty(promoCodeAmount) && isValidAmount(promoCodeAmount)) {
      const createdPromoCode = await postPromoCode.mutateAsync({ credit: formatAmountAsCents(promoCodeAmount), createdBy: currentUser?.currentOrganizationId })
      setPromoCode(createdPromoCode?.code)
      setView(viewEnum.created)
    }
  }

  const promoCodeViews = {
    creation:
      <div data-testid="create-promo-code-container">
        <div className="title" data-testid="promo-code-header-title">Create Promotion</div>
        {errorMessage && <div data-testid="error-message-container" className="promotion-error">{errorMessage}</div>}
        <div className="description">How much would you like this to be for?</div>
        <input
          type="text"
          data-testid="promo-code-input"
          placeholder="$0.00"
          onChange={(e) => checkAndSetAmount(formattedInputAmount(e.target.value))}
          style={{ fontSize: '2rem' }}
          value={promoCodeAmount}
        />
        <div className="account-balance">Account balance after created: {formatAmountAsDollars(subscription?.creditBalance - formatAmountAsCents(promoCodeAmount))}</div>
        <div className="modal-footer">
          <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
          <div className="button" data-testid="submit-promo-code-button" onClick={createPromoCode}>Create</div>
        </div>
      </div>,
    created:
    <div>
        <div className="title">Promotion Created!</div>
        <div className="description">An organization can use this code to apply to their account:</div>
        <div className="created-code">
          <div className="code">{promoCode}</div>
          <div className="icon" onClick={() => navigator.clipboard.writeText(promoCode)}><ContentCopyIcon/></div>
        </div>
        <div className="modal-footer">
          <div className="button" onClick={() => clearModal()}>Close</div>
        </div>
    </div>
  }

  return (
    <>
      {view === viewEnum.creation && promoCodeViews.creation}
      {view === viewEnum.created && promoCodeViews.created}
    </>
  )
}

export default CreatePromoCode
