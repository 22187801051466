import { useRef } from 'react'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import PromoCodeStatus from '../../modals/PromoCodeStatus'
import { useModal } from '../../context/ModalContext'
import { openSupportChat } from '../../shared/utilities'
import './index.css'

function PromoCodeSettingsMenu ({ useOutsideAlerter, setEditPromoCode, promoCode }) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setEditPromoCode })
  const { makeModal } = useModal()

  return (
    <div className='promo-code-settings dropdown' data-testid="promo-code-settings-menu-container" ref={wrapperRef}>
    <Paper
      sx={{ width: 175 }}
      elevation={24}>
      <MenuList dense ref={wrapperRef}>
        {promoCode?.status === 'UNAPPLIED'
          ? <div>
              <MenuItem
                onClick={() => makeModal({
                  modal: <PromoCodeStatus promoCode={promoCode} setEditPromoCode={setEditPromoCode} />,
                  title: 'Revoke promotion'
                })}>
                <ListItemText>Revoke</ListItemText>
              </MenuItem>
              <MenuItem
                  onClick={openSupportChat}>
                <ListItemText>Need help?</ListItemText>
              </MenuItem>
            </div>
          : <div>
              <MenuItem
                onClick={openSupportChat}>
                <ListItemText>Need help?</ListItemText>
              </MenuItem>
            </div>}
        </MenuList>
      </Paper>
    </div>
  )
}

export default PromoCodeSettingsMenu
