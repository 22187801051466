import { useAuthContext } from '../../context/AuthContext'
import LoadingScreen from '../../components/LoadingScreen'
import { useStudioInsightsQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import InsightDisplay from '../../components/InsightDisplay'
import './index.css'

function Insights () {
  const { currentUser } = useAuthContext()
  const { data: insights, isLoading, error, isError } = useStudioInsightsQuery({ organizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  return (
    <div>
      <InsightDisplay data={insights} />
    </div>
  )
}

export default Insights
