import './index.css'

function SecondaryNavAction ({ action, actionClassName, actionTitle }) {
  return (
    <div
      className={`button subnav-button ${actionClassName}`}
      data-testid={'subnav-action-button'}
      onClick={action}>{actionTitle}</div>
  )
}

export default SecondaryNavAction
