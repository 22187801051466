import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useRef } from 'react'

function ExerciseSettingsMenu ({ handleDelete, exerciseSettingsMenu, setExerciseSettingMenu, useOutsideAlerter, exercise }) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setExerciseSettingMenu })

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={exerciseSettingsMenu === exercise.id ? () => setExerciseSettingMenu('') : () => setExerciseSettingMenu(exercise.id)}>
        <MoreHorizIcon style={{ marginTop: '5px', cursor: 'pointer' }}/>
      </div>
      {exerciseSettingsMenu === exercise.id &&
        <div className="program-settings-menu-dropdown-container" style={{ top: '2rem' }} ref={wrapperRef}>
          <Paper
            sx={{ width: 175 }}
            elevation={24}>
            <MenuList dense>
              <MenuItem onClick={() => handleDelete({ exerciseId: exercise.id })}>
                <ListItemText>Delete Exercise</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      }
    </div>
  )
}

export default ExerciseSettingsMenu
