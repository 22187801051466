import { useState, useEffect } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Switch } from '@mui/material'

function CourseMetaDataDisplay ({ courseVersion, handleChange, editCourse, handleSave, editExistingContent, courseErrorMessage }) {
  const [totalCourseExercises, setTotalCourseExercises] = useState(0)

  function adjustDescriptionHeight () {
    const textarea = document?.getElementById('program-description')
    if (!textarea) return
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  useEffect(() => {
    const totalCourseExercises = courseVersion?.sections?.reduce((acc, section) => {
      return acc + (section?.exercises?.length || 0)
    }, 0)
    setTotalCourseExercises(totalCourseExercises)
  }, [courseVersion])

  const courseMetaDataDisplay = (
    <div className="program-meta-data-wrapper" style={{ maxWidth: '100%', width: '80%' }}>
      <div style={{ fontWeight: '600', marginBottom: '0.5rem' }}>
        {courseVersion?.sections?.length} Sections • {totalCourseExercises} Exercises
      </div>
      <div
        onClick={() =>
          editCourse === '' && editExistingContent({ course: { name: courseVersion?.name, description: courseVersion?.description } })
        }
      >
        {courseErrorMessage && <div className="program-error">{courseErrorMessage}</div>}
        <label style={{ display: 'block', marginBottom: '0.5rem' }}>
          {(courseVersion?.name === '' || editCourse !== '') && (
            <input
              type="text"
              style={{ padding: '0.5rem', margin: '5px 0 0 0 !important', display: 'block' }}
              placeholder="Course Name"
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => handleChange({ type: 'course', attribute: 'name', value: e.target.value })}
              value={editCourse?.name}
            />
          )}
          {editCourse === '' && courseVersion?.name !== '' && (
            <span style={{ display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <span style={{ fontWeight: 'bold' }}>Name:</span> {courseVersion?.name}
            </span>
          )}
        </label>
        <label style={{ display: 'block' }}>
          {(courseVersion?.description === '' || editCourse !== '') && (
            <textarea
              type="text"
              style={{ padding: '0.5rem', margin: '2px 0 0 0', display: 'block', width: '100%' }}
              id="program-description"
              onInput={adjustDescriptionHeight()}
              onClick={(e) => {
                e.stopPropagation()
              }}
              placeholder="Course Description"
              onChange={(e) => handleChange({ type: 'course', attribute: 'description', value: e.target.value })}
              value={editCourse?.description}
            />
          )}
          {editCourse === '' && courseVersion?.description !== '' && (
            <span style={{ display: 'block', whiteSpace: 'normal' }}>
              <span style={{ fontWeight: 'bold' }}>Description:</span> {courseVersion?.description}
            </span>
          )}
        </label>
        {(courseVersion?.name === '' || courseVersion?.description === '' || editCourse !== '') && (
          <div className="program-draft-item-save-button-container" style={{ display: 'flex', marginTop: '0.5rem' }}>
            <div onClick={() => handleSave({ saveType: 'saveCourse' })} style={{ marginLeft: '5px', cursor: 'pointer' }}>
              <CheckIcon />
            </div>
            {courseVersion?.name !== '' && courseVersion?.description !== '' && (
              <div onClick={() => editExistingContent({ type: 'course' })} style={{ marginLeft: '5px', cursor: 'pointer' }}>
                <CloseIcon />
              </div>
            )}
          </div>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontWeight: '600', marginRight: '1rem' }}>Display Section Headers</div>
        <Switch
          checked={courseVersion?.displaySectionHeaders}
          onChange={(e) => handleChange({ type: 'displaySectionHeaders', attribute: 'displaySectionHeaders', value: !courseVersion?.displaySectionHeaders })}
        />
      </div>
    </div>
  )

  return (
    <>
      {courseMetaDataDisplay}
    </>
  )
}

export default CourseMetaDataDisplay
