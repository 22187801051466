const roles = {
  owner: 'OWNER',
  admin: 'ADMIN',
  leader: 'LEADER',
  member: 'MEMBER',
  guest: 'GUEST'
}

const courseTypes = {
  selfPaced: 'Self-Paced',
  structured: 'Structured'
}

const enumCourseTypes = {
  selfPaced: 'SELF_PACED',
  structured: 'STRUCTURED'
}

export {
  roles,
  courseTypes,
  enumCourseTypes
}
