import { getCurrentOrganizationRole } from './utilities'

const isAuthorized = ({ policy, currentUser }) => {
  if (policy === undefined || currentUser === undefined) {
    return false
  }
  const currentOrgRole = getCurrentOrganizationRole(currentUser)

  const isAuthorizedOrganizationRole = currentUser?.organizationRoles?.some(role =>
    policy.organization?.id === role.organization?.id &&
    policy.organization?.roles.includes(role.title) &&
    role.status === 'ACTIVE')

  const isAuthorizedSpaceRole = currentOrgRole
    ? currentOrgRole?.spaceRoles?.some(role =>
      policy.space?.id === role.spaceId &&
      policy.space?.roles.includes(role.title))
    : false

  const hasOrganizationRole = currentUser?.organizationRoles?.some(role =>
    policy.organization?.roles.includes(role.title) &&
    role.status === 'ACTIVE') &&
    policy.organization?.id === undefined

  const hasSpaceRole = currentOrgRole
    ? currentOrgRole?.spaceRoles?.some(role =>
      policy.space?.roles.includes(role.title)) &&
    policy.space?.id === undefined
    : false

  const isAuthorized =
    isAuthorizedOrganizationRole ||
    isAuthorizedSpaceRole ||
    hasOrganizationRole ||
    hasSpaceRole
  return isAuthorized
}

export { isAuthorized }
