import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { logError } from './shared/logger'
import { ModalProvider } from './context/ModalContext'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { setupCrisp } from './shared/crisp'
import { setupSentry } from './shared/sentry'
import App from './App'
import AuthProvider from './context/AuthContext'
import ErrorProvider from './context/ErrorContext'
import reportWebVitals from './reportWebVitals'
import './index.css'

setupSentry()
setupCrisp()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        return error?.response?.status >= 500
      }
    }
  },

  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.state?.data !== undefined) {
        logError(error)
      }
    }
  })
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ErrorProvider>
        <AuthProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </AuthProvider>
      </ErrorProvider>
    </QueryClientProvider>
  </BrowserRouter>
)

reportWebVitals()
