import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import CourseMetaDataDisplay from '../CourseMetaDataDisplay'
import SectionsDisplay from '../SectionsDisplay'
import AddOrUpdateCourseProgram from '../../modals/AddOrUpdateCourseProgram'
import PublishCourse from '../../modals/PublishCourse'

import { v4 as uuidv4 } from 'uuid'
import './index.css'

function CourseDraft ({ existingCourse, programId, courseType }) {
  const { makeModal, clearModal } = useModal()

  const exercise = {
    title: 'untitled-exercise',
    url: '',
    id: '',
    publisherAction: false,
    publisherDueBy: 0
  }

  const section = {
    id: '',
    title: 'untitled-section',
    exercises: [],
    achievement: ''
  }

  const course = {
    id: '',
    uid: '',
    name: '',
    description: '',
    sections: [],
    courseStatus: 'DRAFT',
    programId: programId ? parseInt(programId) : 0,
    displaySectionHeaders: true,
    courseType
  }

  const errorMessage = {
    course: '',
    section: '',
    exercise: ''
  }

  const [courseVersion, setCourseVersion] = useState(existingCourse ?? course)
  const [editCourse, setEditCourse] = useState('')
  const [editSection, setEditSection] = useState('')
  const [editExercise, setEditExercise] = useState('')
  const [displayErrorMessage, setDisplayErrorMessage] = useState(errorMessage)

  const hasActions = courseVersion.sections
    ?.flatMap((section) => section.exercises)
    ?.filter((exercise) => exercise.publisherAction)
    ?.length < 0

  const handleChange = ({ type, attribute, value, sectionId }) => {
    const errorType = type.includes('Section') ? 'section' : type.includes('Exercise') ? 'exercise' : 'course'
    setDisplayErrorMessage(prev => ({ ...prev, [errorType]: '' }))

    switch (type) {
      case 'course':
        setEditCourse(prev => ({
          ...prev,
          [attribute]: value
        }))
        break
      case 'displaySectionHeaders':
        setCourseVersion(prev => ({
          ...prev,
          displaySectionHeaders: value
        }))
        break
      case 'newSection':
        setCourseVersion(prev => {
          const idValue = uuidv4().replace(/-/g, '')
          const newSection = {
            ...section,
            id: `${idValue}`
          }
          return {
            ...prev,
            sections: [...prev.sections, newSection]
          }
        })
        break
      case 'existingSection':
        setEditSection(prev => ({
          ...prev,
          [attribute]: value
        }))
        break

      case 'newExercise':
        setCourseVersion(prev => {
          const idValue = uuidv4().replace(/-/g, '')
          return {
            ...prev,
            sections: prev.sections.map(sec =>
              sec.id === sectionId
                ? {
                    ...sec,
                    exercises: [
                      ...sec.exercises,
                      {
                        ...exercise,
                        id: `${idValue}`
                      }
                    ]
                  }
                : sec
            )
          }
        })
        break

      case 'existingExercise':
        setEditExercise(prev => ({
          ...prev,
          [attribute]: value
        }))
        break

      default:
        break
    }
  }

  const validateTitle = (item, type) => {
    if (item === undefined || item?.trim() === '') {
      setDisplayErrorMessage(prev => ({
        ...prev,
        [type]: `Please fill out the ${type} ${type === 'course' ? 'name and description' : 'title'} before saving`
      }))
      return false
    }
    return true
  }

  const saveExistingCourse = () => {
    if (!validateTitle(editCourse.name, 'course')) return
    if (!validateTitle(editCourse.description, 'course')) return
    setCourseVersion(prev => ({
      ...prev,
      name: editCourse.name,
      description: editCourse.description
    }))
    setEditCourse('')
    setDisplayErrorMessage(prev => ({ ...prev, course: '' }))
  }

  const saveExistingSection = (sectionId) => {
    if (!validateTitle(editSection.title, 'section')) return

    setCourseVersion(prev => ({
      ...prev,
      sections: prev.sections.map(section => section.id === sectionId ? editSection : section)
    }))
    setEditSection('')
    setDisplayErrorMessage(prev => ({ ...prev, section: '' }))
  }

  const saveExistingExercise = (exerciseId) => {
    if (!validateTitle(editExercise.title, 'exercise')) return

    setCourseVersion(prev => ({
      ...prev,
      sections: prev.sections.map(section => ({
        ...section,
        exercises: section.exercises.map(exercise => exercise.id === exerciseId ? editExercise : exercise)
      }))
    }))
    setEditExercise('')
    setDisplayErrorMessage(prev => ({ ...prev, exercise: '' }))
  }

  const saveAchievement = (sectionId, achievement) => {
    setCourseVersion(prev => ({
      ...prev,
      sections: prev.sections.map(section => section.id === sectionId ? { ...section, achievement } : section)
    }))
  }

  const saveOrDeleteAction = (exerciseId, type, publisherDueBy) => {
    setCourseVersion(prev => ({
      ...prev,
      sections: prev.sections.map(section => ({
        ...section,
        exercises: section.exercises.map(exercise => exercise.id === exerciseId ? { ...exercise, publisherAction: type === 'saveAction', publisherDueBy } : exercise)
      }))
    }))
  }

  function handleSave ({ saveType, sectionId, exerciseId, achievement, publisherDueBy }) {
    switch (saveType) {
      case 'saveCourse':
        saveExistingCourse()
        break
      case 'saveExistingSection':
        saveExistingSection(sectionId)
        break
      case 'saveExistingExercise':
        saveExistingExercise(exerciseId)
        break
      case 'saveAchievement':
        saveAchievement(sectionId, achievement)
        break
      case 'saveAction':
        saveOrDeleteAction(exerciseId, 'saveAction', publisherDueBy)
        break
      case 'deleteAction':
        saveOrDeleteAction(exerciseId, 'deleteAction', publisherDueBy)
        break
      default:
        break
    }
  }

  function handleDelete ({ sectionId = null, exerciseId = null } = {}) {
    setCourseVersion(prev => ({
      ...prev,
      sections: sectionId
        ? prev.sections.filter(section => section.id !== sectionId)
        : prev.sections.map(section => {
          if (exerciseId && section.exercises.some(exercise => exercise.id === exerciseId)) {
            return {
              ...section,
              exercises: section.exercises.filter(exercise => exercise.id !== exerciseId)
            }
          }
          return section
        })
    }))

    if (sectionId) {
      setEditSection('')
    } else if (exerciseId) {
      setEditExercise('')
    }
  }

  function handlePublish () {
    if (courseVersion?.name?.length === 0 || courseVersion?.description?.length === 0 || courseVersion?.name?.length === undefined || courseVersion?.description?.length === undefined) {
      setDisplayErrorMessage(prev => ({ ...prev, course: 'Please fill out the course name and description before publishing' }))
      return
    }

    if (existingCourse) {
      makeModal({
        modal: <PublishCourse courseVersionData={courseVersion} clearModal={clearModal} />,
        title: 'Publish Course'
      })
    } else {
      makeModal({
        modal: <AddOrUpdateCourseProgram courseVersionData={courseVersion} clearModal={clearModal} isInitialDraft={!existingCourse}/>,
        title: !programId ? 'Add Program to Course' : 'Update Course Program'
      })
    }
  }

  function editExistingContent ({ type = '', course = null, section = null, exercise = null } = {}) {
    if (course) {
      setEditCourse(course)
    } else if (section) {
      setEditSection(section)
    } else if (exercise) {
      setEditExercise(exercise)
    } else {
      switch (type) {
        case 'course':
          setEditCourse('')
          setDisplayErrorMessage(errorMessage)
          break
        case 'section':
          setEditSection('')
          setDisplayErrorMessage(errorMessage)
          break
        case 'exercise':
          setEditExercise('')
          setDisplayErrorMessage(errorMessage)
          break
        default:
          setEditCourse('')
          setEditSection('')
          setEditExercise('')
          setDisplayErrorMessage(errorMessage)
          break
      }
    }
  }

  function handleReorder ({ type = '', sectionId = null, targetSectionId = null, data = null, sourceExercises = null, destinationExercises = null } = {}) {
    if (type === 'sections') {
      setCourseVersion(prev => ({
        ...prev,
        sections: data
      }))
    } else if (type === 'exercises' && sectionId !== null && targetSectionId !== null) {
      setCourseVersion(prev => {
        const sourceSection = prev.sections.find(section => section.id.toString() === sectionId)
        const targetSection = prev.sections.find(section => section.id.toString() === targetSectionId)

        const updatedSourceSection = {
          ...sourceSection,
          exercises: sourceExercises
        }
        const updatedTargetSection = {
          ...targetSection,
          exercises: destinationExercises
        }

        const updatedSections = prev.sections.map(section =>
          section.id.toString() === sectionId
            ? updatedSourceSection
            : section.id.toString() === targetSectionId
              ? updatedTargetSection
              : section
        )

        return {
          ...prev,
          sections: updatedSections
        }
      })
    }
  }

  return (
    <div className="course-wrapper" style={{ paddingBottom: '4rem' }}>
      <div className="marketing-title-header">Course</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CourseMetaDataDisplay
          courseVersion={courseVersion}
          handleChange={handleChange}
          editCourse={editCourse}
          handleSave={handleSave}
          editExistingContent={editExistingContent}
          courseErrorMessage={displayErrorMessage.course}
        />
        <div className="course-meta-buttons">
          <div className="button" onClick={() => handlePublish()} style={{ marginRight: '0.5rem', maxWidth: '140px', maxHeight: '30px', fontSize: '14px', padding: '0.5rem' }}>Save Draft</div>
          <div className="button secondary section-button" onClick={() => handleChange({ type: 'newSection' })}>+ New</div>
        </div>
      </div>
      <div>
        <SectionsDisplay
          handleChange={handleChange}
          handleDelete={handleDelete}
          handleSave={handleSave}
          displayErrorMessage={displayErrorMessage}
          editExistingContent={editExistingContent}
          editExercise={editExercise}
          editSection={editSection}
          courseVersion={courseVersion}
          handleReorder={handleReorder}
          hasActions={hasActions}
          courseType={courseVersion.courseType}
        />
      </div>
    </div>
  )
}

export default CourseDraft
