import './index.css'

function Avatar ({ firstName = '', lastName = '', avatar, ...props }) {
  return (
    <>
    {avatar
      ? <div {...props}>
          <img className="image" src={avatar} alt="user-avatar" data-testid="profile-settings-avatar"/>
        </div>
      : <div {...props} data-testid="profile-settings-default-avatar">
          {firstName[0]}{lastName[0]}
        </div>}
    </>
  )
}

export default Avatar
