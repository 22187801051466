import { roles } from './enums'

const getLeaderPolicy = () => ({
  space: {
    roles: [roles.leader]
  },
  organization: {
    roles: [roles.admin, roles.owner]
  }
})

const getAdminPolicy = () => ({
  organization: {
    roles: [roles.admin, roles.owner]
  }
})

const getOwnerPolicy = () => ({
  organization: {
    roles: [roles.owner]
  }
})

const getSpacePolicy = ({ spaceId, organizationId }) => ({
  space: {
    id: spaceId,
    roles: [roles.member, roles.leader]
  },
  organization: {
    id: organizationId,
    roles: [roles.admin, roles.owner]
  }
})

const getOrganizationPolicy = (organizationId) => ({
  organization: {
    id: organizationId,
    roles: [roles.guest, roles.member, roles.admin, roles.owner]
  }
})

const getSpaceLeaderPolicy = ({ spaceId, organizationId }) => ({
  space: {
    id: spaceId,
    roles: [roles.leader]
  },
  organization: {
    id: organizationId,
    roles: [roles.admin, roles.owner]
  }
})

const getOrganizationAdminPolicy = (organizationId) => ({
  organization: {
    id: organizationId,
    roles: [roles.admin, roles.owner]
  }
})

const getOrganizationOwnerPolicy = (organizationId) => ({
  organization: {
    id: organizationId,
    roles: [roles.owner]
  }
})

const getOrganizationPersonPolicy = (organizationId) => ({
  organization: {
    id: organizationId,
    roles: [roles.member, roles.admin, roles.owner]
  }
})

export {
  getSpacePolicy,
  getOrganizationPolicy,
  getSpaceLeaderPolicy,
  getOrganizationAdminPolicy,
  getOrganizationOwnerPolicy,
  getLeaderPolicy,
  getAdminPolicy,
  getOwnerPolicy,
  getOrganizationPersonPolicy
}
