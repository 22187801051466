import { Outlet } from 'react-router-dom'
import Nav from '../../components/Nav'
import MobileNav from '../../components/MobileNav'

function Layout () {
  return (
    <div>
      <Nav />
      <Outlet />
      <MobileNav />
    </div>
  )
}

export default Layout
