import packageJson from '../../package.json'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from './config'

function setupSentry () {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new BrowserTracing({
      tracingOrigins: ['localhost', 'api.connectbetter.io']
    })],
    tracesSampleRate: 1.0,
    release: 'studio@' + packageJson.version
  })
}

export {
  setupSentry
}
