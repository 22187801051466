import { LAST_ORGANIZATION_ID_KEY } from '../context/AuthContext'
import config from './config'

const makeHeaders = () => {
  const organizationId = localStorage.getItem(LAST_ORGANIZATION_ID_KEY) ?? '0'

  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Organization-Id': organizationId
  }
}

const apiGet = async (path) => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'GET',
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiPut = async (path, body) => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiPost = async (path, body) => {
  const res = await fetch(`${config.API_HOST}${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
  return res.json()
}

const apiDelete = async (path, body) => {
  await fetch(`${config.API_HOST}${path}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: makeHeaders(),
    credentials: 'include'
  })
}

export async function getUser (organizationId) {
  const user = await apiGet('/user')
  const existingOrganizationId =
    parseInt(organizationId) ||
    parseInt(localStorage.getItem(LAST_ORGANIZATION_ID_KEY))

  return {
    ...user,
    currentOrganizationId:
      existingOrganizationId || user?.organizationRoles[0]?.organization?.id
  }
}

export async function getOrganizationPromoCodes (organizationId) {
  return await apiGet(`/communities/${organizationId}/promo_codes`)
}

export async function postPromoCode (body) {
  return await apiPost(`/communities/${body.createdBy}/promo_code`, body)
}

export async function putPromoCode (body) {
  return await apiPut(
    `/communities/${body?.organizationId}/promo_code`,
    body
  )
}

export async function getOrganizationSubscription (organizationId) {
  return await apiGet(`/communities/${organizationId}/subscription`)
}

export async function getPublisherPrograms (publisherOrganizationId) {
  return await apiGet(`/publishers/${publisherOrganizationId}/programs`)
}

export async function getPublisherCourses (publisherOrganizationId) {
  return await apiGet(
    `/publishers/${publisherOrganizationId}/courses`
  )
}

export async function getPublisherProgram ({
  publisherOrganizationId,
  programId
}) {
  return await apiGet(
    `/publishers/${publisherOrganizationId}/programs/${programId}`
  )
}

export async function getPublicPrograms () {
  return await apiGet('/programs')
}

export async function postProgram (body) {
  return await apiPost('/programs', body)
}

export async function putProgram (body) {
  return await apiPut(`/programs/${body?.id}`, body)
}

export async function addOrganizationProgram (body) {
  return await apiPost('/organization_programs', body)
}

export async function removeOrganizationProgram (body) {
  return await apiDelete('/organization_programs', body)
}

export async function getCourse (courseId) {
  return await apiGet(`/publisher_courses/${courseId}`)
}

export async function postCourse (body) {
  return await apiPost('/courses', body)
}

export async function putCourse (body) {
  return await apiPut(`/courses/${body?.course?.id}`, { body })
}

export async function getStudioInsights (organizationId) {
  return await apiGet(`/publishers/${organizationId}/insights`)
}

export async function putCourseProgram ({ body, courseId }) {
  return await apiPut(`/courses/${courseId}/program`, body)
}
