import { ReactSVG } from 'react-svg'
import Button from '../Button'
import './index.css'

function EmptyHero ({ image, title, description, action }) {
  const actions = action?.map((action, index) => {
    const actionOnClick = action?.onClick
    return (<Button key={index} onClick={() => actionOnClick()}>{action?.actionTitle}</Button>)
  })

  return (
    <div className='empty-hero'>
      <div style={{ display: 'flex' }}>
        <ReactSVG src={image} />
        <div className='empty-description-container'>
          <div className='empty-title'>{title}</div>
          <p className='empty-description'>{description}</p>
          {action && actions}
        </div>
      </div>
    </div>
  )
}

export default EmptyHero
